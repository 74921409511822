<template>
  <div>
    <b-modal v-model="modalShow" size="md" :title="title" 
      footer-class="footerClass"
      @hidden="$emit('update:show', false)"
      no-close-on-backdrop  content-class="shadow" modal-class="anti-shift"
      :ok-title="$t('button.select')"
    >
      <b-alert :variant="alertError? 'danger':'success'" dismissible :show="showError" @dismissed="dismissAlert">
        <font-awesome-icon :icon="alertError? ['fas', 'triangle-exclamation'] : ['far', 'check']"/>&nbsp;&nbsp;{{ alertMsg }}
      </b-alert>
      
      <label class="mr-1">{{ $t(`dataview.field.members`) }}</label>
      <button v-if="!readOnly" :id="`ADD_${id}`" class="btn-action" @click="userSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
        <b-popover
          :target="`ADD_${id}`"
          placement="top"
          triggers="hover"
          :content="$t('button.add')">
        </b-popover>
      </button>
      <BadgeGroup v-model="memberList" class="mb-3" :readOnly="readOnly">
        <template v-slot:default="{ item, index }">
          <Badge @badgeRemove="removeMember(index)" @badgeClick="editMember(index)"
            :text="item.name" 
            variant="white" 
            :readOnly="readOnly"
            :pillable="!!item.pillable" :key="index" />
          </template>
      </BadgeGroup>
               
      <template v-slot:modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <template>
          <b-button v-if="!readOnly" size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
        
      </template>
    </b-modal>

    <UserSelectorModal :show.sync="userSelectorShow" @ok="userSelectorOk" :multiple="true" />
  </div>
</template>

<script>
import { userService } from '@/services';

export default {
  name: 'MembersModal',
  components: {
    BadgeGroup: () => import('@/components/BadgeGroup/BadgeGroup'),
    Badge: () => import('@/components/BadgeGroup/components/Badge'),
    UserSelectorModal        : () => import('@/components/modal/UserSelectorModal')
  },
  props: {
    show:         { type: Boolean, required: true },
    members: { type: String, default: null },
    title: { type: String, default: null },
    readOnly: { type: Boolean, default: false }
  },
  data() {
    return {
      id: Math.random().toString(36).substr(2, 9),
      alertError: false,
      alertMsg: null,
      modalShow: false,
      userSelectorShow: false,
      memberList: []
    }
  },
  created() {
  
  },
  beforeDestroy() {

  },
  watch: {
    show(newValue) {
      if(newValue != this.modalShow) {
        this.alertMsg = null;
        this.modalShow = newValue;
        this.memberList = [];
        if (this.members !== null) {
          const self = this;
          const members = this.members.split(',');
          const filter = [
            '_or_', members.map(m => { return ['USER.uuId', 'eq', m] })
          ] 
          userService.list({ start: 0, limit: -1, filter: filter }).then((response) => {
            const users = response.data;
            for (var member of members) {
              const user = users.filter(u => u.uuId === member);
              if (user.length !== 0) {
                self.memberList.push({ uuId: user[0].uuId, name: `${user[0].firstName} ${user[0].lastName}`});
              }
            }
          })
          .catch((e) => {
            console.log(e); // eslint-disable-line no-console
          });
        }
      }
    }
  },
  computed: {
    showError() {
      return this.alertMsg != null;
    }
  },
  methods: {
    dismissAlert() {
      this.alertMsg = null;
      this.alertError = false;
    },
    ok() {
      this.$emit('update:show', false);
      this.$emit('success', this.memberList.map(m => { return m.uuId }).join());
    },
    removeMember(index) {
      this.memberList.splice(index,1);
    },
    editMember(/**index*/) {
    
    },
    userSelectorToggle() {
      this.userSelectorShow = true;
    },
    userSelectorOk(result) {
      const details = result.details;
      for (var detail of details) {
        const list = this.memberList.filter(m => m.uuId === detail.uuId);
        if (list.length === 0) {
          this.memberList.push(detail);
        }
      }
    }
  }
}
</script>
